import { DefaultEntry } from '../../screens/HealthData'

// Regular expression to match the code format {prefix}{number}
const codeRegex = /^([a-zA-Z]*)(\d*)$/

const extractCode = <T extends DefaultEntry>(item: T): string | null => {
	for (const [, value] of Object.entries(item)) {
		if (typeof value === 'string' && value.match(codeRegex)) {
			return value
		}
	}
	return null
}

export const sortByCode = <T extends DefaultEntry>(items: T[]): T[] => {
	return items.sort((a, b) => {
		const codeA = extractCode(a)
		const codeB = extractCode(b)

		if (codeA && codeB) {
			const matchA = codeA.match(codeRegex)
			const prefixA = matchA ? matchA[1] : ''
			const numberA = matchA && matchA[2] ? parseInt(matchA[2], 10) : 0

			// Extract parts from b.Code
			const matchB = codeB.match(codeRegex)
			const prefixB = matchB ? matchB[1] : ''
			const numberB = matchB && matchB[2] ? parseInt(matchB[2], 10) : 0

			// Compare alphabetic prefixes first
			if (prefixA < prefixB) return -1
			if (prefixA > prefixB) return 1

			// If prefixes are the same, compare the numbers numerically
			return numberA - numberB
		}

		return 0
	})
}
