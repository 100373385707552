import { css } from 'styled-components/macro'
import * as React from 'react'

const formStyle = css`
	margin-top: 10px;
	border: 1px solid;
	border-radius: 5px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	align-items: left;
	background-color: #ffff;
	width: 450px;
	margin: auto;

	input {
		width: 100%;
		margin-bottom: 5px;
	}

	input[type='submit'] {
		background-color: green;
		color: white;
		border: none;
		border-radius: 5px;
		padding: 5px;
		margin-top: 10px;
	}

	span {
		font-size: 1.5rem;
	}

	.threshold {
		margin-left: 5px;
		display: flex;
		flex-direction: row;
		gap: 3px;
		align-items: left;
		justify-content: space-evenly;
	}
`

export const highLightStyle = css`
	display: block;
	background-color: #f5f5f5;
	border-radius: 5px;
	box-shadow: 0px 0px 5px #333333;
	padding: 10px;
`

export const FormDialog: React.FC<FormProps> = ({
	sendData,
	editMode,
	goAddMode,
	goEditMode,
	children,
}) => (
	<form
		className='healthDataForm'
		css={formStyle}
		onSubmit={(event) => {
			event.preventDefault()
			sendData()
		}}
		onKeyDown={(event) => {
			if (event.key === 'Escape') {
				editMode ? goEditMode(false) : goAddMode(false)
			}
		}}
	>
		<div
			css={css`
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			`}
		>
			<span
				css={css`
					margin: 5px;
				`}
			>
				{editMode ? 'Edit' : 'Add'}
			</span>
			<button
				title='closeBtn'
				type='button'
				css={css`
					background: none;
					border: none;
					padding: none;
					margin: none;
					float: right;
				`}
				onClick={() => (editMode ? goEditMode(false) : goAddMode(false))}
			>
				<span className='closeIcon'></span>
			</button>
		</div>
		{children}
		<input
			type='submit'
			value='Save'
			css={css`
				margin-top: 10px;
				background-color: green;
			`}
		/>
	</form>
)

interface FormProps {
	sendData: () => void
	editMode: boolean
	goAddMode: (mode: boolean) => void
	goEditMode: (mode: boolean) => void
	children: React.ReactNode
}
