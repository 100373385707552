import Table, { HealthData } from '../../components/HealthData/HealthDataTable'
import React, { useCallback } from 'react'
import useFetch, { CachePolicies } from 'use-http'
import { useHealthDataContext } from './HealthData'
import { ObservationData } from './Observation'
import { HealthDataModelType } from '../../components/HealthData/globaltypes'

const Concept = ({ setRequestResult, sortConcepts, error }: ConceptProps) => {
	const { data, setData } = useHealthDataContext()
	const { concepts } = data

	const { request: fetchRequest } = useFetch(
		`${process.env.REACT_APP_SURVEY_API_URL}`,
		{
			cachePolicy: CachePolicies.NO_CACHE,
		}
	)

	const addConcept = useCallback(
		async (data: HealthData) => {
			try {
				const response = await fetchRequest.post(
					'api/healthdata/concepts',
					data
				)
				const concepts = sortConcepts(response)
				setData((prevState) => ({
					...prevState,
					concepts,
				}))
			} catch (error) {
				setRequestResult(
					error
						? `Failed to add groupEntry Reason: ${String(error)}`
						: 'An unknown error occurred whilst trying to add new concept entry'
				)
			}
		},
		[fetchRequest, setData, setRequestResult, sortConcepts]
	)

	const getConceptRelationalData = useCallback(
		async (
			id: number,
			relationalDataType?: string
		): Promise<ObservationData> => {
			if (relationalDataType === HealthDataModelType.Concept)
				throw new Error(
					'fetch only accepts return types of Observation or Group'
				)

			try {
				return await fetchRequest
					.get(`api/healthdata/concepts/${id}/${relationalDataType}`)
					.then((result) => result)
			} catch (error) {
				throw new Error(
					`An error occurred whilst attempting to fetch concept ${relationalDataType}`
				)
			}
		},
		[fetchRequest]
	)

	const updateConcept = useCallback(
		async (data: HealthData) => {
			try {
				const response = await fetchRequest.put('api/healthdata/concepts', data)
				const concepts = sortConcepts(response)
				setData((prevState) => ({
					...prevState,
					concepts,
				}))
			} catch (error) {
				setRequestResult(
					error
						? `Failed to update groupEntry Reason: ${String(error)}`
						: 'An unknown error occurred whilst trying to update groupEntry'
				)
			}
		},
		[fetchRequest, setData, setRequestResult, sortConcepts]
	)

	const deleteConcept = useCallback(
		async (id: number) => {
			try {
				const response = await fetchRequest.delete(
					`api/healthdata/concepts/${id}`
				)

				const concepts = sortConcepts(response)
				setData((prevState) => ({
					...prevState,
					concepts,
				}))
			} catch (error) {
				setRequestResult(
					error
						? `Failed to delete groupEntry(ID: ${id})\nReason: ${String(error)}`
						: 'An unknown error occurred whilst trying to delete conceptEntry'
				)
			}
		},
		[fetchRequest, setData, setRequestResult, sortConcepts]
	)

	return (
		<Table
			viewData={concepts}
			getRelationalData={getConceptRelationalData}
			error={error}
			addHealthData={addConcept}
			updateHealthData={updateConcept}
			removeHealthData={deleteConcept}
			context='concept'
		/>
	)
}

export default Concept

interface ConceptProps {
	setRequestResult: React.Dispatch<React.SetStateAction<string>>
	sortConcepts: (concepts: ConceptData) => ConceptData
	error: Error
}

export type ConceptEntry = {
	id: number
	title: string
	objective: string
	documentationUrl: string
}

export type ConceptObservations = [
	{
		id: number
		code: string
		title: string
	},
]

export type ConceptGroups = [
	{
		id: number
		code: string
		title: string
	},
]

export type ConceptData = ConceptEntry[]
