import Table, { HealthData } from '../../components/HealthData/HealthDataTable'
import useFetch, { CachePolicies } from 'use-http'
import React, { useCallback } from 'react'
import { DefaultEntry, useHealthDataContext } from './HealthData'
import { GroupData } from './Group'

const Observation: React.FC<ObservationProps> = ({
	setRequestResult,
	sortObservations,
	error,
}) => {
	const { data, setData } = useHealthDataContext()
	const { observations } = data

	const { request: fetchRequest } = useFetch(
		`${process.env.REACT_APP_SURVEY_API_URL}`,
		{
			cachePolicy: CachePolicies.NO_CACHE,
		}
	)

	const addObservation = useCallback(
		async (data: HealthData) => {
			try {
				const response = await fetchRequest.post(
					'api/healthdata/observations',
					data
				)
				const observations = sortObservations(response)
				setData((prevState) => ({
					...prevState,
					observations,
				}))
			} catch (error) {
				setRequestResult(
					error
						? `Failed to add observationentry Reason: ${String(error)}`
						: 'An error occurred'
				)
			}
		},
		[fetchRequest, setData, setRequestResult, sortObservations]
	)

	const getObservationGroups = useCallback(
		async (id: number): Promise<GroupData> => {
			try {
				return await fetchRequest
					.get(`api/healthdata/observations/${id}/groups`)
					.then((result) => result)
			} catch (error) {
				throw new Error('An error occurred whilst attempting to fetch groups')
			}
		},
		[fetchRequest]
	)

	const updateObservation = useCallback(
		async (data: HealthData) => {
			try {
				const response = await fetchRequest.put(
					'api/healthdata/observations',
					data
				)
				const observations = sortObservations(response)
				setData((prevState) => ({
					...prevState,
					observations,
				}))
			} catch (error) {
				setRequestResult(
					error
						? `Failed to update observationentry Reason: ${String(error)}`
						: 'An error occurred'
				)
			}
		},
		[fetchRequest, setData, setRequestResult, sortObservations]
	)

	const deleteObservation = useCallback(
		async (id: number) => {
			try {
				const response = await fetchRequest.delete(
					`api/healthdata/observations/${id}`
				)
				const observations = sortObservations(response)
				setData((prevState) => ({
					...prevState,
					observations,
				}))
			} catch (error) {
				setRequestResult(
					error
						? `Failed to delete groupEntry Reason: ${String(error)}`
						: 'An unknown error occurred whilst trying to delete groupEntry'
				)
			}
		},
		[fetchRequest, setData, setRequestResult, sortObservations]
	)

	return (
		<Table
			viewData={observations}
			getRelationalData={getObservationGroups}
			error={error}
			addHealthData={addObservation}
			updateHealthData={updateObservation}
			removeHealthData={deleteObservation}
			context='observations'
		/>
	)
}

interface ObservationProps {
	setRequestResult: React.Dispatch<React.SetStateAction<string>>
	sortObservations: (observations: ObservationData) => ObservationData
	error: Error
}

export type IntervalTypes =
	| 'number'
	| 'percent'
	| 'seconds'
	| 'minutes'
	| 'hours'
	| 'repetitions'
	| 'mmol/l'
	| 'kcal'
	| 'strokes/min'
	| 'meters/second'

export type ObservationEntry = DefaultEntry & {
	type: IntervalTypes
	groupId?: number
}

export type ObservationData = Array<ObservationEntry>

export default Observation
