import * as React from 'react'
import { useImmerReducer } from 'use-immer'

import editorReducer from './reducers/editorReducer'

import {
	ApiDataType,
	ConceptDispatchType,
	EditorDispatchType,
	SurveyConcept,
} from '../types'
import conceptReducer from './reducers/conceptReducer'

const EditorStateContext = React.createContext<ApiDataType | undefined>(
	undefined
)

const EditorDispatchContext = React.createContext<
	EditorDispatchType | undefined
>(undefined)

const ConceptStateContext = React.createContext<SurveyConcept[] | undefined>(
	undefined
)
const ConceptDispatchContext = React.createContext<
	ConceptDispatchType | undefined
>(undefined)

const EditorProvider = ({
	children,
	data,
}: ProviderProps): React.ReactElement => {
	const [surveyDefinitionState, surveyDispatch] = useImmerReducer(
		editorReducer,
		data
	)
	const [conceptsState, conceptsDispatch] = useImmerReducer(conceptReducer, [])

	return (
		<EditorStateContext.Provider value={surveyDefinitionState}>
			<EditorDispatchContext.Provider value={surveyDispatch}>
				<ConceptStateContext.Provider value={conceptsState}>
					<ConceptDispatchContext.Provider value={conceptsDispatch}>
						{children}
					</ConceptDispatchContext.Provider>
				</ConceptStateContext.Provider>
			</EditorDispatchContext.Provider>
		</EditorStateContext.Provider>
	)
}

const useEditorState = (): ApiDataType => {
	const context = React.useContext(EditorStateContext)

	if (context === undefined) {
		throw new Error('useSurveyState must be used within a SurveyProvider')
	}

	return context
}

const useEditorDispatch = (): EditorDispatchType => {
	const context = React.useContext(EditorDispatchContext)

	if (context === undefined) {
		throw new Error('useSurveyDispatch must be used within a SurveyProvider')
	}

	return context
}

const useConceptState = (): SurveyConcept[] => {
	const context = React.useContext(ConceptStateContext)

	if (context === undefined) {
		throw new Error('useConceptState must be used within a ConceptProvider')
	}

	return context
}

const useConceptDispatch = (): ConceptDispatchType => {
	const context = React.useContext(ConceptDispatchContext)

	if (context === undefined) {
		throw new Error('useConceptDispatch must be used within a SurveyProvider')
	}

	return context
}

export {
	EditorProvider,
	useEditorState,
	useEditorDispatch,
	useConceptState,
	useConceptDispatch,
}

type ProviderProps = {
	children: React.ReactNode
	data: ApiDataType | undefined
}
