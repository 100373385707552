import { useState, useRef, useCallback, useEffect } from 'react'
import { highLightStyle } from '../../FormDialog'
import Threshold from '../../Threshold'
import InfoIcon from '../../../InfoIcon'
import InputControlOptions from './InputControlOptions'
import React from 'react'
import getAvailableCode from '../../../../utils/HealthData/getAvailableCode'
import { AddDialogProps } from './types'
import { css } from 'styled-components/macro'
import { HealthData } from '../../HealthDataTable'
import { useHealthDataContext } from '../../../../screens/HealthData'

const ObservationInputAdd = ({
	addRowData,
	invalidInput,
	data,
	relationalEditState: state,
	relationalDataHandlers,
}: AddDialogProps) => {
	const [highlightedElement, setHighlightedElement] = useState('')
	const codeRef = useRef<HTMLInputElement>(null)
	const {
		createThresholdArray,
		addThreshold,
		updateThreshold,
		removeThreshold,
	} = relationalDataHandlers
	const { thresholds } = state
	const { groups } = useHealthDataContext().data

	const selectGroup = useCallback(
		(event: React.ChangeEvent<HTMLSelectElement>) => {
			const autoCode = getAvailableCode(data, event.target.value)
			if (codeRef.current) {
				if (autoCode) {
					codeRef.current.value = autoCode
					codeRef.current.disabled = true
				} else {
					codeRef.current.value = ''
					codeRef.current.disabled = false
				}
			}
			addRowData('code', event, autoCode)
		},
		[data, addRowData]
	)

	const mapThresholdsToState = useCallback(
		(thresholds: HealthData[]) => {
			if (state.thresholds.length) return thresholds

			return createThresholdArray()
		},
		[createThresholdArray, state]
	)

	useEffect(() => {
		mapThresholdsToState([])
		//eslint-disable-next-line
	}, [])

	return (
		<>
			<label
				htmlFor='code'
				onFocus={() => setHighlightedElement('code')}
				css={[highlightedElement === 'code' && highLightStyle]}
			>
				<InfoIcon description='Unique code for observation. If group is chosen code will be generated automatically.' />
				Code: <br />
				<InfoIcon description='The group this observation belongs to. If group is chosen code will be generated automatically' />
				<select
					name='group'
					id='group'
					onChange={selectGroup}
					css='margin-bottom: 5px;'
				>
					<option value=''>Choose group</option>
					{groups.map((group, index) => (
						<option
							key={
								typeof group.code === 'string' ? `${group.code}_${index}` : ''
							}
							value={typeof group.code === 'string' ? group.code : ''}
						>
							{group.code}
						</option>
					))}
				</select>
				<input
					title='code'
					id='code'
					type='text'
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						addRowData('code', event)
					}
					autoFocus
					ref={codeRef}
					required
				/>
			</label>
			{invalidInput && (
				<p
					css={css`
						color: red;
					`}
				>
					Code already exists
				</p>
			)}
			<label
				htmlFor='title'
				onFocus={() => setHighlightedElement('title')}
				css={[highlightedElement === 'title' && highLightStyle]}
			>
				<InfoIcon description='Title of the healthdata entry' />
				Title:
				<input
					title='title'
					id='title'
					type='text'
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						addRowData('title', event)
					}
					required
				/>
			</label>
			<label
				htmlFor='type'
				onFocus={() => setHighlightedElement('type')}
				css={[highlightedElement === 'type' && highLightStyle]}
			>
				<InfoIcon description='Type of data used for measuring' />
				Type:
				<select
					title='type'
					id='type'
					onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
						addRowData('type', event)
					}
					required
				>
					<option value=''></option>
					<option value='number'>NUMBER</option>
					<option value='percent'>PERCENT</option>
					<option value='seconds'>SECONDS</option>
					<option value='minutes'>MINUTES</option>
					<option value='hours'>HOURS</option>
					<option value='repetitions'>REPETITIONS</option>
					<option value='mmol/l'>MMOL/L</option>
					<option value='kcal'>KCAL</option>
					<option value='strokes/min'>STROKES/MIN</option>
					<option value='meters/second'>SPEED (M/S) </option>
				</select>
			</label>
			<label
				htmlFor='interval_input'
				onFocus={() => setHighlightedElement('interval')}
				css={[highlightedElement === 'interval' && highLightStyle]}
			>
				<InfoIcon description='Interval between the thresholds as a whole or floating point number' />
				Interval:
				<input
					title='interval_input'
					id='interval_input'
					type='number'
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						addRowData('interval', event)
					}
					step='0.01'
					required
				/>
			</label>
			<label
				htmlFor='thresholds_input'
				onFocus={() => setHighlightedElement('thresholds_input')}
				css={[highlightedElement === 'thresholds_input' && highLightStyle]}
			>
				<InfoIcon description='Thresholds on a given min/max range with outcome. Leave max empty if there is no range.' />
				Thresholds
				<br />
				<Threshold
					addThreshold={addThreshold}
					removeThreshold={removeThreshold}
					updateThreshold={updateThreshold}
					thresholds={thresholds}
				/>
			</label>
			<label
				htmlFor='category_input'
				onFocus={() => setHighlightedElement('category')}
				css={[highlightedElement === 'category' && highLightStyle]}
			>
				<InfoIcon description='Category this healthdata entry is part of' />
				Category:
				<input
					title='category_input'
					id='category_input'
					list='categories'
					type='text'
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						addRowData('category', event)
					}
					required
				/>
				<InputControlOptions
					id='categories'
					providesFor='category'
					data={data}
				/>
			</label>
		</>
	)
}

export default ObservationInputAdd
