import { useCallback, useMemo, useReducer } from 'react'
import {
	ObservationData,
	ObservationEntry,
} from '../screens/HealthData/Observation'
import { GroupData, GroupEntry } from '../screens/HealthData/Group'
import { Outcome } from '../components/HealthData/Threshold'
import { HealthData } from '../components/HealthData/HealthDataTable'

export interface State {
	observations: ObservationData
	groups: GroupData
	thresholds: HealthData[]
}

export interface Threshold extends HealthData {
	description?: string
	min?: number
	max?: number
	outcome?: Outcome
}

type Action =
	| { type: 'ADD_OBSERVATION'; payload: ObservationEntry }
	| { type: 'REMOVE_OBSERVATION'; payload: ObservationEntry }
	| { type: 'ADD_GROUP'; payload: GroupEntry }
	| { type: 'REMOVE_GROUP'; payload: GroupEntry }
	| { type: 'ADD_EMPTY_THRESHOLD'; payload: null }
	| { type: 'REMOVE_THRESHOLD'; payload: number }
	| { type: 'UPDATE_THRESHOLD'; payload: ThresholdUpdate }
	| { type: 'INITIAL_STATE'; payload: State }

const threshold: Threshold = {
	description: undefined,
	min: undefined,
	max: undefined,
	outcome: undefined,
}

const reducer = (state: State, action: Action): State => {
	const { payload } = action

	const thresholds = [...state.thresholds]

	switch (action.type) {
		case 'ADD_OBSERVATION':
			const addObservation = payload as ObservationEntry
			return {
				...state,
				observations: [...state.observations, addObservation],
			}
		case 'REMOVE_OBSERVATION':
			const removeObservation = payload as ObservationEntry
			return {
				...state,
				observations: state.observations.filter(
					(item) => item.title !== removeObservation.title
				),
			}
		case 'ADD_GROUP':
			const addGroup = payload as GroupEntry
			return {
				...state,
				groups: [...state.groups, addGroup],
			}
		case 'REMOVE_GROUP':
			const removeGroup = payload as GroupEntry
			return {
				...state,
				groups: state.groups.filter((item) => item.title !== removeGroup.title),
			}
		case 'ADD_EMPTY_THRESHOLD':
			thresholds.push(threshold)
			return {
				...state,
				thresholds,
			}
		case 'UPDATE_THRESHOLD':
			const { threshold: update, index } = payload as ThresholdUpdate
			if (!thresholds.length) {
				thresholds.push(threshold)
			}
			if (!thresholds[index]) {
				for (let i = thresholds.length; i <= index; i++) {
					thresholds.push(threshold)
				}
			}

			thresholds[index] = { ...thresholds[index], ...update }

			return {
				...state,
				thresholds,
			}
		case 'REMOVE_THRESHOLD':
			if (typeof payload !== 'number')
				throw new Error('Requires an index for deleteable entry')

			const filteredArray = thresholds.filter((_, index) => index !== payload)

			if (!filteredArray.length) {
				return {
					...state,
					thresholds: [threshold],
				}
			}

			return {
				...state,
				thresholds: filteredArray,
			}

		case 'INITIAL_STATE':
			return payload as State
		default:
			throw new Error('Unhandled action type')
	}
}

const useHandleRelationalData = (
	observations: ObservationData = [],
	groups = [],
	thresholds = []
): TUseHandleRelationalDataReturn => {
	const initialState: State = useMemo(
		() => ({ observations, groups, thresholds }),
		[groups, observations, thresholds]
	)
	const [relationalEditState, dispatch] = useReducer(reducer, initialState)

	const addObservation = useCallback(
		(item: ObservationEntry) =>
			dispatch({ type: 'ADD_OBSERVATION', payload: item }),
		[dispatch]
	)
	const addGroup = useCallback(
		(item: GroupEntry) => dispatch({ type: 'ADD_GROUP', payload: item }),
		[dispatch]
	)
	const addEmptyThreshold = useCallback(() => {
		return dispatch({ type: 'ADD_EMPTY_THRESHOLD', payload: null })
	}, [dispatch])
	const updateThreshold = useCallback(
		(item: { threshold: HealthData; index: number }) =>
			dispatch({ type: 'UPDATE_THRESHOLD', payload: item }),
		[dispatch]
	)

	const removeGroup = useCallback(
		(item: GroupEntry) => dispatch({ type: 'REMOVE_GROUP', payload: item }),
		[dispatch]
	)

	const removeObservation = useCallback(
		(item: ObservationEntry) =>
			dispatch({ type: 'REMOVE_OBSERVATION', payload: item }),
		[dispatch]
	)

	const createThresholdArray = useCallback(() => {
		addEmptyThreshold()

		const arr: Threshold[] = []
		arr.push(threshold)

		return arr
	}, [addEmptyThreshold])

	const removeThreshold = useCallback((index: number) => {
		return dispatch({ type: 'REMOVE_THRESHOLD', payload: index })
	}, [])

	const validateHasThresholds = useCallback((): boolean => {
		const { thresholds } = relationalEditState

		switch (true) {
			case !thresholds.length:
			case thresholds.filter((t) => t.description === undefined).length > 0:
			case thresholds.filter((t) => t.outcome === undefined).length > 0:
			case thresholds.filter((t) => t.description === '').length > 0:
			case thresholds.filter((t) => t.outcome === '').length > 0:
				return false
			default:
				return true
		}
	}, [relationalEditState])

	const revertChanges = useCallback(
		() => dispatch({ type: 'INITIAL_STATE', payload: initialState }),
		[initialState]
	)

	return {
		relationalEditState,
		addObservation,
		removeObservation,
		addGroup,
		removeGroup,
		updateThreshold,
		createThresholdArray,
		removeThreshold,
		validateHasThresholds,
		revertChanges,
		addThreshold: addEmptyThreshold,
	}
}

export default useHandleRelationalData

type TRelationalData = {
	relationalEditState: State
}

export type TRelationalDataHandlers = {
	addObservation: (item: ObservationEntry) => void
	removeObservation: (item: ObservationEntry) => void
	addGroup: (item: GroupEntry) => void
	removeGroup: (item: GroupEntry) => void
	addThreshold: () => void
	updateThreshold: (item: ThresholdUpdate) => void
	createThresholdArray: () => HealthData[]
	removeThreshold: (index: number) => void
	validateHasThresholds: () => boolean
	revertChanges: () => void
}

type ThresholdUpdate = { threshold: HealthData; index: number }

type TUseHandleRelationalDataReturn = TRelationalData & TRelationalDataHandlers
