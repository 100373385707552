import * as React from 'react'
import Table, { HealthData } from '../../components/HealthData/HealthDataTable'
import { ObservationData } from './Observation'
import useFetch, { CachePolicies } from 'use-http'
import { DefaultEntry, useHealthDataContext } from './HealthData'
import { useCallback } from 'react'

const Group = ({ setRequestResult, sortGroups, error }: GroupProps) => {
	const { data, setData } = useHealthDataContext()
	const { groups } = data

	const { request: fetchRequest } = useFetch(
		`${process.env.REACT_APP_SURVEY_API_URL}`,
		{
			cachePolicy: CachePolicies.NO_CACHE,
		}
	)

	const addGroup = useCallback(
		async (data: HealthData) => {
			try {
				const response = await fetchRequest.post('api/healthdata/groups', data)
				const groups = sortGroups(response)
				setData((prevState) => ({
					...prevState,
					groups,
				}))
			} catch (error) {
				setRequestResult(
					error
						? `Failed to add groupEntry Reason: ${String(error)}`
						: 'An unknown error occurred whilst trying to add new groupEntry'
				)
			}
		},
		[fetchRequest, setData, setRequestResult, sortGroups]
	)

	const getGroupObservations = useCallback(
		async (id: number): Promise<ObservationData> => {
			try {
				return await fetchRequest
					.get(`api/healthdata/groups/${id}/observations`)
					.then((result) => result)
			} catch (error) {
				throw new Error(
					'An error occurred whilst attempting to fetch observations'
				)
			}
		},
		[fetchRequest]
	)

	const updateGroup = useCallback(
		async (data: HealthData) => {
			try {
				const response = await fetchRequest.put('api/healthdata/groups', data)
				const groups = sortGroups(response)
				setData((prevState) => ({
					...prevState,
					groups,
				}))
			} catch (error) {
				setRequestResult(
					error
						? `Failed to update groupEntry Reason: ${String(error)}`
						: 'An unknown error occurred whilst trying to update groupEntry'
				)
			}
		},
		[fetchRequest, setData, setRequestResult, sortGroups]
	)

	const deleteGroup = useCallback(
		async (id: number) => {
			try {
				const response = await fetchRequest.delete(
					`api/healthdata/groups/${id}`
				)

				const groups = sortGroups(response)
				setData((prevState) => ({
					...prevState,
					groups,
				}))
			} catch (error) {
				setRequestResult(
					error
						? `Failed to delete groupEntry(ID: ${id})\nReason: ${String(error)}`
						: 'An unknown error occurred whilst trying to delete groupEntry'
				)
			}
		},
		[fetchRequest, sortGroups, setData, setRequestResult]
	)

	return (
		<Table
			viewData={groups}
			getRelationalData={getGroupObservations}
			error={error}
			addHealthData={addGroup}
			updateHealthData={updateGroup}
			removeHealthData={deleteGroup}
			context='group'
		/>
	)
}

export default Group

interface GroupProps {
	setRequestResult: React.Dispatch<React.SetStateAction<string>>
	sortGroups: (groups: GroupData) => GroupData
	error: Error
}

export type IntervalTypes =
	| 'SUM of observations'
	| 'SUM of depressionscale'
	| 'SUM of agstscale'
	| 'number'
	| 'points'

export type GroupEntry = DefaultEntry & {
	type: IntervalTypes
}

export type GroupObservations = [
	{
		id: number
		code: string
	},
]

export type GroupData = GroupEntry[]
