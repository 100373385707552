import {
	Switch,
	Route,
	useLocation,
	useRouteMatch,
	useHistory,
	useParams,
} from 'react-router-dom'
import { useQueryParams, NumberParam, StringParam } from 'use-query-params'
import { useFetch, CachePolicies } from 'use-http'
import { css } from 'styled-components/macro'

import {
	useEditorState,
	useEditorDispatch,
	useConceptState,
	useConceptDispatch,
} from '../../context/EditorProvider'

import {
	formatSurveyForExport,
	formatTranslationsForExport,
} from '../../utils/formatSurvey'
import { getNewTranslations } from '../../utils/getNewTranslations'
import { transformSurveyData } from '../../utils/transformSurveyData'

import Button, { ButtonVariant } from '../../components/Button'
import Alert, { AlertVariant } from '../../components/Alert'
import Spinner from '../../components/Spinner'
import Container from '../../components/Container'

import QuestionEditor from '../QuestionEditor'
import Translations from '../Translations'
import NotFound from '../NotFound'
import TagPreview from '../TagPreview'
import TagRules from '../TagRules'
import PageMenu from './PageMenu'
import QuestionList from './QuestionList'

import {
	TranslationsType,
	SurveyDefinitionType,
	isApiDataType,
	SurveyQueryParams,
	SurveyHealthData,
} from '../../types'
import ConfirmDeleteDialog from '../../components/ConfirmDeleteDialog'
import TextInput from '../../components/TextInput'
import ConceptSelector from './ConceptSelector'
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'

const SurveyEditor: FC = () => {
	return <SurveyRouter />
}

const SurveyRouter: FC = () => {
	const [tempConceptId, setTempConceptId] = useState<number>(0)
	const { surveyDefinition, translations } = useEditorState()
	const surveyDispatch = useEditorDispatch()
	const conceptOptions = useConceptState()
	const conceptDispatch = useConceptDispatch()

	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false)

	const { search } = useLocation()
	const { path } = useRouteMatch()
	const [queryParams] = useQueryParams({
		userId: NumberParam,
		orgId: NumberParam,
		deptId: NumberParam,
		hash: StringParam,
		culture: StringParam,
	})

	const history = useHistory()

	const {
		userId,
		orgId: userOrgId,
		deptId: userDeptId,
		hash: userHash,
		culture: userCulture,
	} = queryParams

	const { deptToRequest: deptToRequestRaw, orgToRequest: orgToRequestRaw } =
		useParams<SurveyQueryParams>()

	const deptToRequest = deptToRequestRaw && parseInt(deptToRequestRaw)
	const orgToRequest = orgToRequestRaw && parseInt(orgToRequestRaw)

	const [tempOrgId, setTempOrgId] = useState<number | undefined>(
		typeof orgToRequest === 'number' && orgToRequest >= 0
			? orgToRequest
			: undefined
	)
	const [tempDeptId, setTempDeptId] = useState<number | undefined>(
		typeof deptToRequest === 'number' && deptToRequest >= 0
			? deptToRequest
			: undefined
	)
	const [inputError, toggleInputError] = useState<boolean>(false)

	const [requestErrorText, setRequestErrorText] = useState<string>('')
	const [requestSuccess, setRequestSuccess] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const { request: fetchRequest } = useFetch(
		`${process.env.REACT_APP_SURVEY_API_URL}`,
		{
			cachePolicy: CachePolicies.NO_CACHE,
		}
	)

	const { request: saveRequest } = useFetch(
		`${process.env.REACT_APP_SURVEY_API_URL}`,
		{
			cachePolicy: CachePolicies.NO_CACHE,
		}
	)

	const { request: deleteRequest } = useFetch(
		process.env.REACT_APP_SURVEY_API_URL,
		{
			cachePolicy: CachePolicies.NO_CACHE,
		}
	)

	const fetchConceptOptions = useCallback(
		async () => fetchRequest.get('api/survey/healthdata/concepts'),
		[fetchRequest]
	)

	const isCurrentSurvey =
		tempDeptId === surveyDefinition?.sourceDeptId &&
		tempOrgId === surveyDefinition?.sourceOrgId

	const resetStateAndFetchSurvey = async ({
		orgId,
		deptId,
	}: fetchSurveyInput) => {
		setRequestErrorText('')
		setRequestSuccess('')
		surveyDispatch({
			type: 'setData',
			payload: {
				surveyDefinition: undefined,
				translations: undefined,
			},
		})
		fetchSurvey({ orgId, deptId })
	}

	const fetchSurvey = async ({ orgId, deptId }: fetchSurveyInput) => {
		setIsLoading(true)

		const result = await fetchRequest.post(
			`/api/survey/${userId}/${userOrgId}/${userDeptId}/${userHash}`,
			{
				OrgId: orgId,
				DeptId: deptId,
			}
		)

		if (result && fetchRequest && !fetchRequest.error) {
			const prunedData = transformSurveyData(result)

			if (isApiDataType(prunedData)) {
				surveyDispatch({
					type: 'setData',
					payload: prunedData,
				})
			} else {
				setRequestErrorText('Could not load Survey')

				surveyDispatch({
					type: 'setData',
					payload: { surveyDefinition: undefined, translations: undefined },
				})
			}
		} else {
			setRequestErrorText('Could not load Survey')

			surveyDispatch({
				type: 'setData',
				payload: { surveyDefinition: undefined, translations: undefined },
			})
		}

		setIsLoading(false)
	}

	const saveSurvey = async ({
		orgId,
		deptId,
		conceptId,
		surveyDefinition,
		translations,
	}: SaveSurveyDefinitionInput) => {
		setRequestErrorText('')
		setRequestSuccess('')
		setIsLoading(true)

		const formattedSurvey = formatSurveyForExport({
			userId,
			orgId,
			deptId,
			surveyDefinition,
			conceptId,
		})

		await saveRequest.patch(
			`/api/survey/${userId}/${userOrgId}/${userDeptId}/${userHash}`,
			{
				Survey: formattedSurvey,
				Translations: translations ? getNewTranslations(translations) : [],
			}
		)

		setIsLoading(false)

		if (saveRequest && !saveRequest.error) {
			setRequestSuccess('Survey saved')
			const newUrl = path.replace(
				':orgToRequest?/:deptToRequest?',
				`${orgId}/${deptId}`
			)
			history.push({
				pathname: newUrl,
				search: search,
			})
			fetchSurvey({ orgId, deptId })
		} else {
			if (
				saveRequest &&
				saveRequest.error &&
				saveRequest.error.name === '409'
			) {
				setRequestErrorText(
					`A survey allready exists for this organization and department. You must delete it before overwriting.`
				)
			} else {
				setRequestErrorText('An error occured while trying to save the survey')
			}
		}
	}

	const deleteSurvey = async ({ orgId, deptId }: deleteSurveyInput) => {
		if (orgId === 0 && deptId === 0) return

		setRequestErrorText('')
		setRequestSuccess('')

		setIsLoading(true)

		await deleteRequest.delete(
			`/api/survey/${userId}/${userOrgId}/${userDeptId}/${userHash}`,
			{
				OrgId: orgId,
				DeptId: deptId,
			}
		)

		if (deleteRequest && !deleteRequest.error) {
			setRequestSuccess('Survey deleted')
			if (
				orgId === surveyDefinition?.sourceOrgId &&
				deptId === surveyDefinition?.sourceDeptId
			) {
				surveyDispatch({
					type: 'setData',
					payload: { surveyDefinition: undefined, translations: undefined },
				})
			}
		} else {
			if (
				deleteRequest &&
				deleteRequest.error &&
				deleteRequest.error.name === '404'
			) {
				setRequestErrorText("The survey you tried to delete doesn't exist")
			} else {
				setRequestErrorText(
					'An error occured while trying to delete the specified survey'
				)
			}
		}

		setIsLoading(false)
	}

	useEffect(() => {
		if (
			typeof orgToRequest !== 'number' ||
			orgToRequest < 0 ||
			typeof deptToRequest !== 'number' ||
			deptToRequest < 0
		) {
			setIsLoading(false)
			toggleInputError(true)
			return
		}
		resetStateAndFetchSurvey({ orgId: orgToRequest, deptId: deptToRequest })
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		fetchConceptOptions().then((data: SurveyHealthData) => {
			conceptDispatch({ type: 'setConcepts', payload: data })
			const id: number = surveyDefinition?.conceptId ?? 0
			setTempConceptId(id)
		})
	}, [conceptDispatch, fetchConceptOptions, surveyDefinition?.conceptId])

	if (isLoading)
		return (
			<Fragment>
				<Container>
					<Spinner />
				</Container>
			</Fragment>
		)

	const clearStatusText = () => {
		setRequestErrorText('')
		setRequestSuccess('')
	}

	const onLoadMasterClick = () => {
		toggleInputError(false)
		const newUrl = path.replace(':orgToRequest?/:deptToRequest?', `0/0`)

		history.push({
			pathname: newUrl,
			search,
		})

		resetStateAndFetchSurvey({ deptId: 0, orgId: 0 })
	}

	const onLoadSpecifiedClick = () => {
		if (
			typeof tempOrgId !== 'number' ||
			typeof tempDeptId !== 'number' ||
			tempOrgId < 0 ||
			tempDeptId < 0 ||
			Number.isNaN(tempOrgId) ||
			Number.isNaN(tempDeptId)
		) {
			toggleInputError(true)
			return
		}

		const newUrl = path.replace(
			':orgToRequest?/:deptToRequest?',
			`${tempOrgId}/${tempDeptId}`
		)

		history.push({
			pathname: newUrl,
			search: search,
		})

		resetStateAndFetchSurvey({
			orgId: tempOrgId,
			deptId: tempDeptId,
		})
	}

	const onExportClick = () => {
		const data = {
			SurveyDefinition: formatSurveyForExport({
				userId,
				orgId: orgToRequest,
				deptId: deptToRequest,
				surveyDefinition,
			}),
			Translations: formatTranslationsForExport(translations),
		}

		const dataStr = JSON.stringify(data)
		const dataUri =
			'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr)

		const exportFileDefaultName = 'data.json'

		const linkElement = document.createElement('a')
		linkElement.setAttribute('href', dataUri)
		linkElement.setAttribute('download', exportFileDefaultName)
		linkElement.click()
	}

	const onDeleteClick = async () => {
		if (
			typeof tempOrgId !== 'number' ||
			typeof tempDeptId !== 'number' ||
			tempOrgId < 0 ||
			tempDeptId < 0 ||
			Number.isNaN(tempOrgId) ||
			Number.isNaN(tempDeptId)
		) {
			toggleInputError(true)
			return
		}

		setConfirmDeleteOpen(true)
	}

	const onSaveAndPreviewClick = async () => {
		if (
			typeof tempOrgId !== 'number' ||
			typeof tempDeptId !== 'number' ||
			tempOrgId < 0 ||
			tempDeptId < 0 ||
			Number.isNaN(tempOrgId) ||
			Number.isNaN(tempDeptId)
		) {
			toggleInputError(true)
			return
		}

		if (
			tempOrgId === 0 &&
			tempDeptId === 0 &&
			!window.confirm('Are you sure you wish to save to master?')
		) {
			return
		}

		if (surveyDefinition && translations) {
			await saveSurvey({
				orgId: tempOrgId,
				deptId: tempDeptId,
				conceptId: tempConceptId,
				surveyDefinition,
				translations,
			})

			const searchParams = `userId=${userId}&orgId=${tempOrgId}&deptId=${tempDeptId}&hash=${userHash}${
				userCulture ? `&culture=${userCulture}` : ''
			}`

			if (saveRequest && !saveRequest.error) {
				window.open(`${window.location.origin}/SurveyHandler?${searchParams}`)
			}
		}
	}

	const onSaveToSpecifiedClick = async () => {
		if (
			typeof tempOrgId !== 'number' ||
			typeof tempDeptId !== 'number' ||
			tempOrgId < 0 ||
			tempDeptId < 0 ||
			Number.isNaN(tempOrgId) ||
			Number.isNaN(tempDeptId)
		) {
			toggleInputError(true)
			return
		}

		if (
			tempOrgId === 0 &&
			tempDeptId === 0 &&
			!window.confirm('Are you sure you wish to save to master?')
		) {
			return
		}

		if (surveyDefinition && translations) {
			saveSurvey({
				orgId: tempOrgId,
				deptId: tempDeptId,
				conceptId: tempConceptId,
				surveyDefinition,
				translations,
			})
		}
	}

	const onOrgChange = (newOrg: string) => {
		toggleInputError(false)
		setTempOrgId(parseInt(newOrg))
	}

	const onDeptChange = (newDept: string) => {
		toggleInputError(false)
		setTempDeptId(parseInt(newDept))
	}

	const onConceptChange = (newConcept: string) => {
		if (!surveyDefinition) return

		const conceptId =
			conceptOptions?.find((c) => c.title === newConcept)?.id ?? 0
		setTempConceptId(conceptId)
		const surveyUpdate = { ...surveyDefinition, conceptId }
		surveyDispatch({
			type: 'setData',
			payload: {
				surveyDefinition: surveyUpdate,
				translations,
			},
		})
	}

	const getSelectedConcept = () => {
		if (tempConceptId === 0) return ''
		return conceptOptions?.find((c) => c.id === tempConceptId)?.title ?? ''
	}

	return (
		<Switch>
			<Route exact path={path}>
				{surveyDefinition ? (
					<PageMenu clearStatusText={clearStatusText} />
				) : null}
				<Container>
					{requestErrorText ? (
						<Alert variant={AlertVariant.error}>
							<p>{requestErrorText}</p>
						</Alert>
					) : null}
					{requestSuccess ? (
						<Alert variant={AlertVariant.success}>
							<p>{requestSuccess}</p>
						</Alert>
					) : null}
					<div
						css={css`
							display: flex;
							flex-grow: 1;
							width: 100%;
							gap: 1rem;
						`}
					>
						<TextInput
							id='org-id'
							label='Organization Id:'
							type='number'
							inputCss={css`
								border: 1px solid black;
								border-radius: 3px;
							`}
							containerCss={css`
								flex-grow: 1;
							`}
							value={
								typeof tempOrgId === 'number' && tempOrgId >= 0 ? tempOrgId : ''
							}
							onChange={(event) => onOrgChange(event.currentTarget.value)}
						/>
						<TextInput
							id='dept-id'
							label='Department Id:'
							type='number'
							inputCss={css`
								border: 1px solid black;
								border-radius: 3px;
							`}
							containerCss={css`
								flex-grow: 1;
							`}
							value={
								typeof tempDeptId === 'number' && tempDeptId >= 0
									? tempDeptId
									: ''
							}
							onChange={(event) => onDeptChange(event.currentTarget.value)}
						/>
					</div>
					{inputError ? (
						<Alert
							variant={AlertVariant.error}
							containerCss={css`
								margin-top: 1rem;
							`}
						>
							<p>
								Both organization id and department id needs to be filled in
							</p>
						</Alert>
					) : null}
					<div css={buttonGroupStyle}>
						<Button
							variant={ButtonVariant.secondary}
							type='button'
							onClick={onLoadMasterClick}
						>
							Load Master survey
						</Button>
						<Button
							variant={ButtonVariant.secondary}
							type='button'
							onClick={onLoadSpecifiedClick}
						>
							Load specified survey
						</Button>
						{surveyDefinition ? (
							<Fragment>
								<Button
									variant={ButtonVariant.secondary}
									onClick={onExportClick}
								>
									Export survey
								</Button>
							</Fragment>
						) : null}
					</div>
					<div css={buttonGroupStyle}>
						<Button
							variant={ButtonVariant.danger}
							onClick={onDeleteClick}
							disabled={tempDeptId === 0 && tempOrgId === 0}
						>
							Delete specified survey
						</Button>
						<ConfirmDeleteDialog
							open={confirmDeleteOpen}
							setOpen={setConfirmDeleteOpen}
							orgId={tempOrgId}
							deptId={tempDeptId}
							isCurrentSurvey={isCurrentSurvey}
							onConfirm={deleteSurvey}
						/>
						{surveyDefinition ? (
							<Fragment>
								<Button
									variant={ButtonVariant.primary}
									onClick={onSaveAndPreviewClick}
								>
									Save & preview
								</Button>
								<Button
									variant={ButtonVariant.primary}
									onClick={onSaveToSpecifiedClick}
								>
									Save to specified organization
								</Button>
							</Fragment>
						) : null}
					</div>

					{!surveyDefinition ||
					!translations ||
					!deptToRequestRaw ||
					!orgToRequestRaw ? null : (
						<Fragment>
							<ConceptSelector
								selected={getSelectedConcept()}
								concepts={conceptOptions}
								onUpdate={onConceptChange}
							/>
							<QuestionList clearStatusText={clearStatusText} />
						</Fragment>
					)}
				</Container>
			</Route>
			<Route path={`${path}/translations`}>
				<Translations />
			</Route>
			<Route path={`${path}/question/:questionIndex`}>
				<QuestionEditor />
			</Route>
			<Route path={`${path}/tagpreview`}>
				<TagPreview />
			</Route>
			<Route path={`${path}/tagrules`}>
				<TagRules />
			</Route>
			<Route>
				<Container>
					<NotFound />
				</Container>
			</Route>
		</Switch>
	)
}

export default SurveyEditor

type fetchSurveyInput = {
	orgId: number
	deptId: number
}

type deleteSurveyInput = {
	orgId: number
	deptId: number
}

type SaveSurveyDefinitionInput = {
	orgId: number
	deptId: number
	conceptId: number
	surveyDefinition?: SurveyDefinitionType
	translations?: TranslationsType
}

const buttonGroupStyle = css`
	display: flex;
	margin: 0.5rem 0;
	gap: 1rem;

	> button,
	a {
		flex-basis: 0;
		flex-grow: 1;
		text-align: center;
		padding: 0.5rem 0.75rem;
	}
`
