import React, { useState } from 'react'
import { AddDialogProps } from './types'
import { highLightStyle } from '../../HealthDataTable'
import { HealthDataModelType } from '../../globaltypes'
import InfoIcon from '../../../InfoIcon'
import RelationalUpdater from '../RelationalContent/RelationalUpdater'

const ConceptInputAdd = ({
	addRowData,
	invalidInput,
	data,
	relationalEditState: state,
	relationalDataHandlers,
}: AddDialogProps) => {
	const [highlightedElement, setHighlightedElement] = useState('')

	return (
		<>
			<label
				htmlFor='title'
				onFocus={() => setHighlightedElement('title')}
				css={[highlightedElement === 'title' && highLightStyle]}
			>
				<InfoIcon description='Title of the concept' />
				Title:
				<input
					title='title'
					id='title'
					type='text'
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						addRowData('title', event)
					}
					required
				/>
			</label>
			<label
				htmlFor='objective'
				onFocus={() => setHighlightedElement('objective')}
				css={[highlightedElement === 'objective' && highLightStyle]}
			>
				<InfoIcon description='A short description or objective of the concept entry' />
				Objective:
				<input
					title='objective'
					id='objective'
					type='text'
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						addRowData('objective', event)
					}
					required
				/>
			</label>
			<label
				htmlFor='objective'
				onFocus={() => setHighlightedElement('objective')}
				css={[highlightedElement === 'objective' && highLightStyle]}
			>
				<InfoIcon description='A link towards any relevant documentation' />
				Documentation Url:
				<input
					title='documentation'
					id='documentationUrl'
					type='url'
					placeholder='http//example.com'
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						addRowData('documentationUrl', event)
					}
					required
				/>
			</label>

			<RelationalUpdater
				updateHandlers={relationalDataHandlers}
				state={state}
				context={HealthDataModelType.Concept}
			/>
		</>
	)
}

export default ConceptInputAdd
